import React, { Fragment } from "react"

const AccountTypes = ({ data }) => {

  return (
    <div className="bg-black" id="account-types">

        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">

                <h2 className="text-lg font-semibold leading-6 tracking-wider text-gray-100 uppercase">
                  TRADER FUNDING PROGRAM ACCOUNT TYPES
                </h2>

                <p className="text-3xl font-extrabold text-green sm:text-4xl lg:text-5xl">
                  Which Account Type is Best for You?
                </p>

            </div>
          </div>
        </div>

      <div className="pb-12 mt-8 bg-black sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 bg-black h-3/4"></div>
            <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-12 lg:gap-5 lg:space-y-0">
                {/* 1nd card */}
              <div className="flex flex-col overflow-hidden rounded-lg shadow-lg col-span-5">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <Fragment>
                      <div className="flex items-baseline mt-4 text-4xl font-extrabold">
                        Immediate Live Trading Accounts
                      </div>
                    </Fragment>
                  </div>

                  <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      <li key={2} className="flex items-start">
                        <div className="flex-shrink-0 text-green text-2xl">
                          &#8226;
                        </div>
                        <p className="ml-3 text-black text-xl">
                          Access a live trading account immediately
                        </p>
                      </li>
                      <li key={3} className="flex items-start">
                        <div className="flex-shrink-0 text-green text-2xl">
                          &#8226;
                        </div>
                        <p className="ml-3 text-black text-xl">
                          Account sizes from $5k to $100k
                        </p>
                      </li>
                    </ul>

                    <div className="shadow">
                      <a
                        className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent face-blue-bg face-blue-btn-hover cursor-pointer"
                        onClick={e => (window.location.href = '/instant-funding')}
                      >
                        Learn More | Create your Account
                      </a>
                    </div>

                  </div>
                </div>

              <div className="text-green col-span-2 text-4xl font-extrabold text-center">
                  <br/>
                  OR
                  <br />
                  <br />
                </div>
                {/* 2nd card */}
                 <div className="flex flex-col overflow-hidden rounded-lg shadow-lg col-span-5">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <Fragment>
                      <div className="flex items-baseline mt-4 text-4xl font-extrabold">
                        1-Step Assessment Plans
                      </div>
                    </Fragment>
                  </div>

                  <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      <li key={2} className="flex items-start">
                        <div className="flex-shrink-0 text-green text-2xl">
                          &#8226;
                        </div>
                        <p className="ml-3 text-black text-xl">
                          Pass an assessment before trading a live account
                        </p>
                      </li>
                      <li key={3} className="flex items-start">
                        <div className="flex-shrink-0 text-green text-2xl">
                          &#8226;
                        </div>
                        <p className="ml-3 text-black text-xl">
                          Account sizes from $25k to $1m
                        </p>
                      </li>
                    </ul>

                    <div className="shadow">
                      <a
                        className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent face-blue-bg face-blue-btn-hover cursor-pointer"
                        onClick={e => (window.location.href = '/one-step-funding')}
                      >
                        Learn More | Create your Account
                      </a>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

      </div>
    </div>
  )
}

export default AccountTypes
